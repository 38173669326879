<script lang="ts">
import {useConfigStore} from "~/store";

export default {
  name: 'HtmlFromConfig',
  props: {
    htmlName: { type: String, required: true}
  },
  setup() {
    const configStore = useConfigStore()
    return {
      configStore,
    }
  },
  computed : {
    pageHtml () : string {
      return this.configStore?.currentConfig?.pages[this.htmlName] || ""
    },
  }
}
</script>

<template>
  <div v-html="pageHtml" />
</template>
